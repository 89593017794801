export const ssoUrl = `${process.env.REACT_APP_SSO_OPENAPIPAAS_URL}`;
const resourceDomain = localStorage.getItem('resourceDomain');
const getEnvVariable = (key) => process.env[key];

let urls = {
  baseUrl: getEnvVariable(`REACT_APP_BASE_${resourceDomain?.toUpperCase()}_STAGING_URL`),
  prodUrl: getEnvVariable(`REACT_APP_BASE_${resourceDomain?.toUpperCase()}_PRODUCTION_URL`),
  sandboxUrl: getEnvVariable(`REACT_APP_BASE_${resourceDomain?.toUpperCase()}_SANDBOX_URL`),
  resourceOwnerUrl: getEnvVariable(`REACT_APP_RESOURCE_${resourceDomain?.toUpperCase()}_URL`),
}

let commonConfigs = {
  ssoHeader: {
    "api-key": getEnvVariable(`REACT_APP_SSO_${resourceDomain?.toUpperCase()}_API_KEY`),
    "project-id": getEnvVariable(`REACT_APP_SSO_${resourceDomain?.toUpperCase()}_PROJECT_ID`),
    "platform-syscode": process.env.REACT_APP_PLATFORM_SYSCODE
  },
  testHeader: (token) => ({
    'Authorization': `Bearer ${token}`,
    "api-key": getEnvVariable(`REACT_APP_DEVELOPER_${resourceDomain?.toUpperCase()}_STAGING_API_KEY`),
    "project-id": getEnvVariable(`REACT_APP_DEVELOPER_${resourceDomain?.toUpperCase()}_STAGING_PROJECT_ID`),
    "platform-syscode": process.env.REACT_APP_PLATFORM_SYSCODE
  }),
  apiHeader: {
    "api-key": getEnvVariable(`REACT_APP_DEVELOPER_${resourceDomain?.toUpperCase()}_STAGING_API_KEY`),
    "project-id": getEnvVariable(`REACT_APP_DEVELOPER_${resourceDomain?.toUpperCase()}_STAGING_PROJECT_ID`),
    "platform-syscode": process.env.REACT_APP_PLATFORM_SYSCODE
  },
  sandboxApiHeader: {
    "api-key": getEnvVariable(`REACT_APP_DEVELOPER_${resourceDomain?.toUpperCase()}_SANDBOX_API_KEY`),
    "project-id": getEnvVariable(`REACT_APP_DEVELOPER_${resourceDomain?.toUpperCase()}_SANDBOX_PROJECT_ID`),
    "platform-syscode": process.env.REACT_APP_PLATFORM_SYSCODE
  },
  prodApiHeader: {
    "api-key": getEnvVariable(`REACT_APP_DEVELOPER_${resourceDomain?.toUpperCase()}_PRODUCTION_API_KEY`),
    "project-id": getEnvVariable(`REACT_APP_DEVELOPER_${resourceDomain?.toUpperCase()}_PRODUCTION_PROJECT_ID`),
    "platform-syscode": process.env.REACT_APP_PLATFORM_SYSCODE
  },
  resourceOwnerApiHeader: {
    "api-key": getEnvVariable(`REACT_APP_RESOURCE_${resourceDomain?.toUpperCase()}_API_KEY`),
    "project-id": getEnvVariable(`REACT_APP_RESOURCE_${resourceDomain?.toUpperCase()}_PROJECT_ID`),
    "platform-syscode": process.env.REACT_APP_PLATFORM_SYSCODE
  },

  apiUrls: {
    ssoUserAuth: () => `${ssoUrl}/auth/users`,
    ssoAdminAuth: () => `${ssoUrl}/auth/business`,
    login: () => `/web/auth/login`,
    refreshAccessToken: () => `/web/auth/token/refresh`,

    //Staging Developer Projects Apis
    GetAllDeveloperProjects: (developer_sso_id) =>
      `/developers/${developer_sso_id}/projects`,
    GetOneDeveloperProjects: (developer_sso_id, developer_proj_id) =>
      `/developers/${developer_sso_id}/projects/${developer_proj_id}`,
    CreateDeveloperProject: () => `/developers/projects`,
    UpdateDeveloperProject: (developer_proj_id) =>
      `/developers/projects/${developer_proj_id}`,
    getProjects: () => `/resource-group/projects`,

    //Portfolio Apis
    getAllPortfolioProjects: (mobileNum) =>
      `/developers/portfolios/mobile/${mobileNum}`,

    // Access Pages Apis
    getAllAccessPages: () =>
      `/developers/access`,
    createAccessPage: () =>
      `/developers/access`,
    updateAccessPage: (access_id) =>
      `/developers/access/${access_id}`,
    deleteAccessPage: (access_id) =>
      `/developers/access/${access_id}`,
    
    validateVoucher: (account_id, voucher_code) =>
      `/common/org/${process.env.REACT_APP_RESOURCE_OPENAPIPAAS_ORG_ID}/vouchers/developers/${account_id}?&voucher_code=${voucher_code}`,

    // Access Roles Apis
    getAllAccessRoles: () => `/developers/roles`,
    createAccessRole: () => `/developers/roles`,
    updateAccessRole: (role_id) => `/developers/roles/${role_id}`,
    deleteAccessRole: (role_id) => `/developers/roles/${role_id}`,

    // Configurations Apis
    // GetAllBillingConfig: () =>
    //   `${beveatUrl}/resource-groups/projects/billing-config`,
    // // `http://localhost:8080/api/v1/resource-groups/projects/billing-config`,
    // GetOneBillingConfig: (billing_config_id) =>
    //   `${beveatUrl}/resource-groups/projects/billing-config/${billing_config_id}`,
    // CreateBillingConfig: () =>
    //   `${beveatUrl}/resource-groups/projects/billing-config`,
    // UpdateBillingConfig: (billing_config_id) =>
    //   `${beveatUrl}/resource-groups/projects/billing-config/${billing_config_id}`,
    // DeleteBillingConfig: (billing_config_id) =>
    //   `${beveatUrl}/resource-groups/projects/billing-config/${billing_config_id}`,

    // GetAllSubscriptionPlan: () => `${beveatUrl}/resource-groups/subscriptions`,
    // // `http://localhost:8080/api/v1/resource-groups/subscriptions`,
    // GetOneSubscriptionPlan: (subscription_id) =>
    //   `${beveatUrl}/resource-groups/subscriptions/${subscription_id}`,
    // CreateSubscriptionPlan: () => `${beveatUrl}/resource-groups/subscriptions`,
    // UpdateSubscriptionPlan: (subscription_id) =>
    //   `${beveatUrl}/resource-groups/subscriptions/${subscription_id}`,
    // DeleteSubscriptionPlan: (subscription_id) =>
    //   `${beveatUrl}/resource-groups/subscriptions/${subscription_id}`,
  },
};

export { urls, commonConfigs };