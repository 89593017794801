import { createContext, useState } from "react";

const tabs = [
    { name: "Staging", value: 0 },
    { name: "Production", value: 1 },
    { name: "Tech Support", value: 2 },
    { name: "Certification", value: 3 },
  ];

export const subscriptionTabsContext = createContext();
export const subscriptionCurrentTabContext = createContext();
export const SubscriptionContextProvider = (props) => {
  const [currentTab, setTab] = useState(tabs[0]);
  const setTabByValue = (value) => {
    const matchingTab = tabs.find((tab) => tab.value === value);
    if (matchingTab) {
      setTab(matchingTab);
    }
  };
  const contextValue = { currentTab, setTab, setTabByValue };
  return (
    <subscriptionTabsContext.Provider value={{ tabs }}>
      <subscriptionCurrentTabContext.Provider value={contextValue}>
        {props.children}
      </subscriptionCurrentTabContext.Provider>
    </subscriptionTabsContext.Provider>
  );
};
