import { Link } from "react-router-dom";
import { format } from "date-fns";

const ProjectCard = ({ data, index, environment }) => {
  const renderEnabledAPIs = () => {
    const projects = data.access
      .filter((project) => project.project_name !== "beveatshop")
      .map((project) => project.project_name);
    if (projects.length === 1) {
      return projects[0];
    } else {
      return projects.join(" | ");
    }
  };

  return (
    <div
      className="bg-white rounded-2xl"
      style={{
        height: "inherit",
        width: "100%",
        minHeight: 0,
        minWidth: 0,
        boxShadow: "5px 10px #EBECF0",
        marginTop: "20px",
        borderWidth: "0.5px",
        borderColor: "black",
        borderStyle: "solid",
        fontSize: "16px",
      }}
    >
      <div
        className="p-3"
        style={{
          display: "grid",
          gridTemplateAreas: `'text1 text1 text1' 'text2 text2 text2''text3 text3 text3''text4 text4 text4''button1 button1 button1'`,
          gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
          gap: 4,
          maxWidth: "inherit",
          color: "black",
        }}
      >
        {/* header */}
        <div
          className="font-bold"
          style={{ gridArea: "text1", textAlign: "left" }}
        >
          Community Project {index + 1}
        </div>

        {/* details */}
        <div className="pt-10" style={{ gridArea: "text2", textAlign: "left" }}>
          {data.developer_proj_name}
        </div>

        <div className="pt-10" style={{ gridArea: "text3", textAlign: "left" }}>
          {renderEnabledAPIs()}
        </div>

        {/* last update */}
        <div
          className="pt-4 text-gray-700"
          style={{ gridArea: "text4", textAlign: "left" }}
        >
          Created on:
          {format(new Date(data.created_on), "dd MMM yyyy HH:mm")}
        </div>

        {/* buttons */}
        <div style={{ gridArea: "button1", marginBottom: "-35px" }}>
          <Link
            to={`/projects/details`}
            state={{
              developerSsoId: data.developer_sso_id,
              developerProjId: data.developer_proj_id,
              projectIndex: index + 1,
              environment: environment,
            }}
          >
            <button
              type="button"
              className="hover:bg-blue-500 text-blue-500 hover:text-white outline outline-blue-500 py-2 px-12 rounded-full text-sm mt-8 mb-5"
              style={{ textAlign: "center", width: "80%" }}
            >
              View More
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
