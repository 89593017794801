import { Link } from "react-router-dom";
   
  export default function SubscriptionCard() {
    return (
        <div
      className="bg-white rounded-2xl"
      style={{
        height: "inherit",
        width: "100%",
        minHeight: 0,
        minWidth: 0,
        boxShadow: "5px 10px #EBECF0",
        marginTop: "20px",
        borderWidth: "0.5px",
        borderColor: "black",
        borderStyle: "solid",
        fontSize: "16px",
      }}
    >
      <div
        className="p-3"
        style={{
          display: "grid",
          gridTemplateAreas: `'text1 text1 text1' 'text2 text2 text2''text3 text3 text3'`,
          gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
          gap: 4,
          maxWidth: "inherit",
          color: "black",
        }}
      >
        {/* header */}
        <div
          className="font-bold"
          style={{ gridArea: "text1", textAlign: "left" }}
        >
          Explorer Plan
        </div>

        {/* details */}
        <div className="pt-10" style={{ gridArea: "text2", textAlign: "left" }}>
          DevProj1 • DevProj2
        </div>

        {/* last update */}
        <div
          className="pt-4 text-gray-700"
          style={{ gridArea: "text3", textAlign: "left" }}
        >
          Expires on: 24 March 2025
        </div>
      </div>
    </div>
    );
  }