import { Link } from "react-router-dom";
import { format } from "date-fns";

const HomePageCard = ({ data, text, header, icon, onClick }) => {
  return data ? (
    <>
      <Link
        to={"/sandbox/details"}
        state={{
          developerSsoId: data.developer_sso_id,
          developerProjId: data.developer_proj_id,
          sandboxIndex: 1,
        }}
        className="bg-white text-black rounded-3xl p-5 w-80 h-52 flex justify-center items-center shadow-md transition-transform duration-300 hover:scale-105"
      >
        <div style={{ marginTop: "-32%", marginLeft: "-15%" }}>
          <h3 className="font-bold text-left" style={{ fontSize: "19px" }}>
            {data.developer_proj_name}
          </h3>
          <p className="text-sm mt-2 pt-4 text-left">
            Last Updated on:{" "}
            {format(new Date(data.created_on), "dd MMM yyyy HH:mm")}
          </p>
        </div>
      </Link>
    </>
  ) : (
    <>
      <div
        className="bg-white text-black rounded-3xl p-5 w-80 h-52 flex justify-center items-center shadow-md transition-transform duration-300 hover:scale-105"
        style={{flexDirection: "column"}}
        onClick={onClick}
      >
        <div>
          <img 
            src={icon} 
            className="h-16 w-16"
            style={{marginBottom: "30%"}}
          />
        </div>
        <h3 className="font-bold" style={{ fontSize: "19px", marginBottom: "5%" }}>{header}</h3>
        <p className="text-center" style={{marginBottom: "10%"}}>{text}</p>
      </div>
    </>
  );
};

export default HomePageCard;
