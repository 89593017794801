import SubscriptionLayout from './subscriptionLayout'
import { SubscriptionContextProvider } from '../context/subscriptionContext';

// this displays the layout for project page
const SubscriptionPage = () => {
  return (
    <SubscriptionContextProvider>
      <SubscriptionLayout />
    </SubscriptionContextProvider>
  );
};

export default SubscriptionPage;