export default function CourseCard({
  industry,
  price,
  discountText,
  benefits,
  buttonText,
  onButtonClick,
}) {
  return (
    <>
      <div className="bg-white shadow-lg rounded-lg w-80 h-[26rem] flex flex-col justify-between">
        <div className="p-6 text-black">
          <h2
            className="text-gray-700 font-bold mb-2"
            style={{ fontSize: "25px" }}
          >
            Industry
          </h2>
          <div className="bg-blue-400 text-white font-bold text-lg py-1 rounded-full mb-2">
            {industry}
          </div>
          <p className="text-3xl font-bold text-gray-800 mb-1">
            <span className="line-through text-black">${price}</span>
          </p>
          <p className="text-red-600 text-sm mb-4">{discountText}</p>
          <ul
            className="text-left"
            style={{ listStyleType: "circle", paddingLeft: "20px" }}
          >
            {benefits.map((benefit, index) => (
              <li key={index} className="mb-2">
                {benefit}
              </li>
            ))}
          </ul>
        </div>
        <button
          onClick={onButtonClick}
          className="bg-blue-400 text-white py-2 px-4 w-full font-semibold hover:bg-blue-600"
        >
          {buttonText}
        </button>
      </div>
    </>
  );
}
