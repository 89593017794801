import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { subscriptionCurrentTabContext } from "../../context/subscriptionContext";
import SubscriptionMenuTabs from "../../components/subscriptionMenuTabs";
import SandboxPlan from "../components/staging/sandboxPlan";
import ComingSoonModal from "../../../../commons/components/comingSoonModal";
import Organisation from "../../../../assets/Organisation.svg";
import Student from "../../../../assets/Student.svg";
import MembershipCard from "../components/membershipCard";
import CourseCard from "../components/certification/courseCard";
import { urls, commonConfigs } from "../../../../config/config";

const PricingPlanLayout = () => {
  const [load, setLoad] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [projects, setProjectsData] = useState([]);
  const [projectNames, setProjectsNames] = useState([]);
  const [comingSoonDate, setComingSoonDate] = useState(null);
  const [industryApiOptions, setIndustryApiOptions] = useState([]);
  const [organisationApiOptions, setOrganisationApiOptions] = useState([]);
  const { currentTab } = useContext(subscriptionCurrentTabContext);
  const navigate = useNavigate();
  const getStagingProjectsUrl =
    urls.baseUrl + commonConfigs.apiUrls.getProjects();

  const handleComingSoonClick = (date) => {
    setComingSoonDate(date);
    setOpen(true);
  };

  async function getStagingApisData() {
    await axios
      .get(getStagingProjectsUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        // console.log(res.data.result.data);
        const projects = res.data.result.data;
        const industryApiOptions = projects
          .filter((project) => project.project_billing_config_id == 1 && 
          project.project_name.toLowerCase().includes('industry'));

        const organisationApiOptions = projects
          .filter((project) => project.project_billing_config_id == 1 && 
          !project.project_name.toLowerCase().includes('industry'));
        
        setIndustryApiOptions(industryApiOptions);
        setOrganisationApiOptions(organisationApiOptions);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  
  async function getPresetData() {
    const projectNamesString = "foodcanopy|payeatfwd|nets_uat_industry_foodservices|beveatshop"
    const projectNames = projectNamesString.split('|').map(name => name.toLowerCase());
    // console.log(projectNames)
    setProjectsNames(projectNames);
    // await axios
    //   .get(getStagingProjectsUrl, { headers: commonConfigs.apiHeader })
    //   .then((res) => {
    //     console.log(res.data.result.data);
    //     const projectNamesString = res.data.result.data.project_name_preset
    //     const projectNames = projectNamesString.split('|').map(name => name.toLowerCase());
    //     setProjectsNames(projectNames);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }

  useEffect(() => {
    getStagingApisData().catch((err) => {
      console.log("err for staging projects : " + err);
    });
    getPresetData().catch((err) => {
      console.log("err for staging projects : " + err);
    });
  }, [])

  const handleApplyClick = () => {
    const industryApis = industryApiOptions
    .filter(project => projectNames.includes(project.project_name))
    .map(project => ({
      value: project.project_id,
      label: project.project_name,
    }));
    const organisationApis = organisationApiOptions
    .filter(project => projectNames.includes(project.project_name))
    .map(project => ({
      value: project.project_id,
      label: project.project_name,
    }));
    let environment;
    if (currentTab.name === "Certification" || currentTab.name === "Staging") {
      environment = { value: "0", label: "Staging" }
    } else {
      environment = { value: "1", label: "Production" }
    }
    const presetOptions = {
      environment: environment,
      industryApiOptions: industryApis,
      organisationApiOptions: organisationApis,
      subscriptionId: { value: "1", label: "IHL-NETS-Fintech Full Stack Developer Course" },
    }
    navigate("/projects/create", {
      state: { presetOptions: presetOptions },
    });
  };

  if (!load) {
    return (
      <div>
        <div className="mt-4 desktop:space-x-8  mobile:pt-2  mobile:pb-4 mobile:space-x-2">
          <div style={{ display: "inline-flex", float: "right" }}>
            {/* <Link to="/subscriptions/pricing-plan"> */}
            <button
              onClick={() => handleComingSoonClick()}
              type="button"
              className="hover:bg-blue-500 text-blue-500 hover:text-white outline outline-blue-500 font-bold py-2 px-12 rounded-full mr-6"
              style={{ float: "right" }}
            >
              Invoices
            </button>
            {/* </Link> */}
            <Link to="/subscriptions">
              <button
                type="button"
                className="hover:bg-blue-500 text-blue-500 hover:text-white outline outline-blue-500 font-bold py-2 px-12 rounded-full mr-6"
                style={{ float: "right" }}
              >
                Subscriptions
              </button>
            </Link>
          </div>
          {/* calling the Card component */}
          <div className="mt-4 desktop:space-x-8  mobile:pt-2  mobile:pb-4 mobile:space-x-2 sm:ml-5">
            <div
              className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2"
              style={{ marginBottom: "-20px" }}
            >
              <SubscriptionMenuTabs />
            </div>
            {currentTab.name == "Staging" && (
              // <div
              //   className="mt-8 flex flex-grow gap-x-5 justify-end pr-10"
              //   style={{
              //     display: "grid",
              //     gridTemplateColumns: "1fr 1fr 1fr 1fr",
              //     marginLeft: "5px",
              //   }}
              // >
              //   <SandboxPlan />
              // </div>
              <>
                <div className="min-h-screen pt-16 pb-12 flex flex-col ">
                  <div className="py-16">
                    <div className="text-center">
                      <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                        Coming Soon
                      </h1>
                    </div>
                  </div>
                </div>
              </>
            )}
            {currentTab.name == "Production" && (
              <>
                <div className="min-h-screen pt-16 pb-12 flex flex-col ">
                  <div className="py-16">
                    <div className="text-center">
                      <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                        Coming Soon
                      </h1>
                    </div>
                  </div>
                </div>
              </>
            )}
            {currentTab.name == "Tech Support" && (
              <>
                <div className="min-h-screen pt-16 pb-12 flex flex-col ">
                  <div className="py-16">
                    <div className="text-center">
                      <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                        Coming Soon
                      </h1>
                    </div>
                  </div>
                </div>
              </>
            )}
            {currentTab.name == "Certification" && (
              <>
                <div
                  className="text-center p-5 rounded-2xl relative mt-2"
                  style={{
                    backgroundColor: "#2b2d42",
                    color: "white",
                    borderRadius: "24px",
                    margin: "0 auto",
                    maxWidth: "90%",
                  }}
                >
                  <h1 className="mb-14" style={{ fontSize: "36px" }}>
                    Sign up for CodeForeword Membership Today
                  </h1>
                </div>

                <div
                  className="flex justify-center items-center gap-x-36 relative"
                  style={{ marginTop: "-60px" }}
                >
                  <MembershipCard
                    text="Enjoy 90% Discounts"
                    header="Student Chapter Membership"
                    icon={Student}
                    onClick={() => handleComingSoonClick("Mid November")}
                  />
                  <MembershipCard
                    text="Enjoy 50% Discounts"
                    header="Organisation Membership"
                    icon={Organisation}
                    onClick={() => handleComingSoonClick("End November")}
                  />
                </div>
                <div className="mt-4 mb-8 text-left">
                  <h1
                    className="text-2xl font-bold text-black"
                    style={{ fontSize: "30px" }}
                  >
                    IHL-NETS–Fintech Curriculum
                  </h1>
                </div>
                <div className="flex items-start gap-8 mt-4">
                  <CourseCard
                    industry="Food Services"
                    price="5000.00"
                    discountText="with Course Code"
                    benefits={[
                      "Full Stack Developer Coursework",
                      "OPENAPI PAAS Product, Voice & NETS Online Payment APIs",
                      "Order-Fulfillment APIs",
                    ]}
                    buttonText="Apply Course Code"
                    onButtonClick={() => handleApplyClick()}
                  />
                  <CourseCard
                    industry="Air Transport"
                    price="7000.00"
                    discountText="with Course Code"
                    benefits={[
                      "Full Stack Developer Coursework",
                      "OPENAPI PAAS Product, Voice & NETS Online Payment APIs",
                      "Order-Fulfillment APIs",
                      "Drone Traffic Management APIs",
                    ]}
                    buttonText="Apply Course Code"
                    onButtonClick={() => handleComingSoonClick()}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        {isOpen && (
          <ComingSoonModal
            setOpenModal={setOpen}
            comingSoonDate={comingSoonDate}
          />
        )}
      </div>
    );
  }
};

export default PricingPlanLayout;
