import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Card from '../components/subscriptionCard'
import { subscriptionCurrentTabContext } from "../context/subscriptionContext";
import SubscriptionMenuTabs from "../components/subscriptionMenuTabs";
// import AddSubscriptionModal from '../components/addSubscriptionModal'
import ComingSoonModal from "../../../commons/components/comingSoonModal";

const SubscriptionLayout = () => {
  const [load, setLoad] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const { currentTab } = useContext(subscriptionCurrentTabContext);

  const handleComingSoonClick = () => {
    setOpen(true);
  };
 
  if (!load) {
    return (
      <div>
        <div className="mt-4 desktop:space-x-8  mobile:pt-2  mobile:pb-4 mobile:space-x-2">
          {/* button for 'Create Project' */}
          <div style={{display: 'inline-flex', float: 'right'}}>
            {/* <Link to="/subscriptions/pricing-plan"> */}
                <button type="button"
                  onClick={handleComingSoonClick}
                  className="hover:bg-blue-500 text-blue-500 hover:text-white outline outline-blue-500 font-bold py-2 px-12 rounded-full mr-6" 
                  style={{float: 'right'}}
                >
                  Invoices
                </button>
            {/* </Link> */}
            {/* <AddSubscriptionModal/> */}
            <Link to="/subscriptions/pricing-plan">
                <button type="button"
                className="hover:bg-blue-500 text-blue-500 hover:text-white outline outline-blue-500 font-bold py-2 px-12 rounded-full mr-6" 
                style={{float: 'right'}}>
                Pricing Plans
                </button>
            </Link>
            {isOpen && (
              <ComingSoonModal
                setOpenModal={setOpen}
              />
            )}
          </div>
          <div className="mt-4 desktop:space-x-8  mobile:pt-2  mobile:pb-4 mobile:space-x-2 sm:ml-5">
            <div
              className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2"
              style={{ marginBottom: "-20px" }}
            >
              <SubscriptionMenuTabs />
            </div>
            {currentTab.name == "Staging" && (
              <div className="mt-8 flex flex-grow gap-x-5 justify-end pr-10" style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', marginLeft: '5px'}}>
                <Card/>
                <Card/>
                <Card/>
                <Card/>
                <Card/>
              </div>
            )}
            {currentTab.name == "Production" && (
              <div className="mt-8 flex flex-grow gap-x-5 justify-end pr-10" style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', marginLeft: '5px'}}>
                <Card/>
                <Card/>
                <Card/>
              </div>
            )}
            {currentTab.name == "Tech Support" && (
              <div className="mt-8 flex flex-grow gap-x-5 justify-end pr-10" style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', marginLeft: '5px'}}>
                <Card/>
              </div>
            )}
            {currentTab.name == "Certification" && (
              <div className="mt-8 flex flex-grow gap-x-5 justify-end pr-10" style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', marginLeft: '5px'}}>
                <Card/>
                <Card/>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default SubscriptionLayout;
